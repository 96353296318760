<template>
  <div class="contextual-help">
    <div class="contextual-help__tip">
      <Tip v-if="customAnimation !== 'camera-on' & customAnimation !== 'focus-qr'" text="Richte deine Kamera auf den H&M HOME QR-Code, um die Animation zu starten" />
      <Tip v-if="customAnimation === 'focus-qr' & customAnimation !== 'camera-on'" text="Richte deine Kamera auf den H&M HOME QR-Code, um die Animation zu starten" />
      <Tip v-if="customAnimation === 'camera-on'" text="Entdecke H&M HOME mit deiner Kamera" />
    </div>
    <button @click=buttonClick style="z-index: 100;position: absolute;">skip</button>
    <div class="contextual-help__screen" :class="customAnimation">
      <router-link to="/ar-view">
        <div class="contextual-help__image">
<!--          <HelpImage />-->
        </div>
      </router-link>
      <div class="contextual-help__note">
        <p>Pay attention to your surroundings and be careful of the driveway</p>
      </div>
      <div class="contextual-help__controls" v-if="customAnimation === 'camera-on'">
        <CameraControls legend />
      </div>
    </div>
  </div>
</template>

<script>
import Tip from '@/components/Tip.vue'
import HelpImage from '@/assets/images/help-screen.svg?inline'
import CameraControls from '@/components/CameraControls.vue'

export default {
  name: 'ContextualHelp2',
  components: {
    Tip,
    HelpImage,
    CameraControls
  },
  data () {
    return {
      customAnimation: ''
    }
  },
  methods: {
    buttonClick(){
      store.set('scannedQR','door')
    },
    focusQR () {
      store.set('ar', true);
      console.log('step-1');

      var s1 = store.sub(['scannedQR'], (qr) => {
        if(qr === 'key' || qr === 'door'){
          console.log('step-2');

          //this.customAnimation = 'focus-qr'
          this.customAnimation = 'camera-on'
//          this.showCam();
          this.goAR();

          s1();
        }
      })
    },
    showCam () {
      setTimeout(() => {
          console.log('code-scanned');
          this.customAnimation = 'camera-on';
          this.goAR();
      }, 5000);
    },
    goAR () {
      setTimeout(() => {
        ARController.setCookie('tutorial', 'viewed', 7);
        router.push({path: 'ar-experience'});
      }, 5000)
    }
  },
  async mounted () {
    store.set('currentPage', 'ContextualHelp2');

    if(ARController.getCookie('tutorial')){
      router.push({path: 'ar-experience'});
    } else {
      this.focusQR()
    }
    //this.showCam()
    //this.goAR()
  }
}
</script>

<style lang="scss">
.contextual-help {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.contextual-help__screen {
  width: 100%;
  height: 100%;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 101%;
    height: 101%;
    background-image: url("../assets/images/frame_inner.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transition: all 0.6s ease;
  }
}

.contextual-help__image {
  display: block;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease 0.2s;
  transform-origin: center;

  svg {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    .corner {
      opacity: 0;
      transition: opacity 0.3s ease 0.3s;
    }
  }
}

.contextual-help__note {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 0 20px 35px;
  text-align: center;
  font-size: 14px;
  line-height: 1.3;
  z-index: 1;
  transition: opacity 0.3s ease;

  p {
    max-width: 180px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.focus-qr {
  &.contextual-help__screen {
    &::after {
      background-size: auto 200%;
      opacity: 0;
      pointer-events: none;
    }
  }

  .contextual-help__image {
    transform: scale(1.1);
    svg {
      .corner {
        opacity: 1;
      }
    }
  }

  .contextual-help__note {
    opacity: 0;
    pointer-events: none;
  }
}

.camera-on {
  &.contextual-help__screen {
    &::after {
      background-size: auto 200%;
      opacity: 0;
      pointer-events: none;
    }
  }

  .contextual-help__image {
    transform: scale(1.25) translateY(7%);
    svg {
      .corner {
        opacity: 0;
      }
    }
  }

  .contextual-help__note {
    opacity: 0;
    pointer-events: none;
  }
}
</style>
